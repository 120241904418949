import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Seo from "../components/seo"
import { getImage } from "gatsby-plugin-image";
import FullWidthImage from "../components/FullWidthImage";
import { Row, Col} from "react-bootstrap";

// eslint-disable-next-line
export const TutorialsPageTemplate = ({
    title,
    content,
    contentComponent,
    heading,
    description,
    heroImage,
}) => {
    const PageContent = contentComponent || Content;
    const heroImg = getImage(heroImage) || heroImage;
    const alt = `Gastrokalk Kunden Tutorials`;

    return (
        <>
            <Row>
                <Col className="col-lg-8 pb-2">
                    <h1 className="page-title title-border">
                        {title}
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col className="col-lg-8 offset-lg-2">
                    <div>
                        <div className="accent-border-bottom-orange sub-hero-text">{heading}</div>
                        <PageContent content={content} />
                    </div>
                    <a href="https://www.youtube.com/@gastrokalk" target="_blank" rel="noopener noreferrer"><FullWidthImage img={heroImg} alt={alt} /></a>
                </Col>
            </Row>
        </>
    );
};

const TutorialsPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;
    const location = "tutorials"

    return (
        <Layout location={location}>
            <TutorialsPageTemplate
                title={frontmatter.title}
                heading={frontmatter.heading}
                description={frontmatter.description}
                heroImage={frontmatter.heroImage}
                contentComponent={HTMLContent}
                content={data.markdownRemark.html}
            />
        </Layout>
    );
};

TutorialsPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            content: PropTypes.string,
            frontmatter: PropTypes.object,
        }),
    }),
};

export default TutorialsPage;

export const Head = () => <Seo title="Tutorials" />

export const faqPageQuery = graphql`
  query TutorialsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        description
        heroImage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
